import React from 'react';
import parse from 'html-react-parser';
import { localizedString } from '@languages';
import Message from '@lib/components/v2/Message';

/**
 * Timeout
 */
export const Timeout = (props) => {
  return (
    <Message {...props} title={localizedString('app.timeout.title')} issue>
      {parse(localizedString('app.timeout.content'))}
    </Message>
  );
};
