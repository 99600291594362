/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isFunction from 'lodash/isFunction';
import '../../../styles/main.scss';
import CaptureExtra from '@lib/pages/v2/CaptureExtra';
import Modal from '@lib/components/v2/Modal';

import { DetailMatch, Questionnaire } from '@containers';

import {
  copyOpenSafari,
  getCookie,
  isAndroidDevice,
  isBrowserPermitted,
  isShownPrivacy,
  isWebRTCSupported,
  setCookie
} from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString } from '@languages';
import parse from 'html-react-parser';
import { PrivacyScreen } from '@lib/pages/v2/PrivacyScreen';
import Message from '@lib/components/v2/Message';
import {
  DETAIL_MATCH,
  DETAIL_MATCH_ONLY_UNIVERSAL_URL,
  FLOW_V2_FORCE_ALTERNATE_FLOW,
  FLOW_V2_SHOW_WELCOME,
  IMMEDIATE_REDIRECT,
  UNIVERSAL_URL_PATH
} from '@spotMobileConfig';
import LoadingBar from '@lib/components/v2/LoadingBar';
import { Timeout } from '@components/Errors';
import { DeviceIncompatibleOpenChrome, InternetCut } from '../../errors';
import {
  AlternateFlow,
  Capture,
  Datacheck,
  FaceScan,
  MoreInfo,
  ProofOfAddress,
  Success,
  VerifyDetails,
  Welcome
} from '..';
import { Language, LoadingSpinner } from '../../components';
import { SCREENS } from './Screens';

class App extends Component {
  constructor(props) {
    super(props);

    const { appConfig, flowType } = this.props;
    const { disableVerifyDetailsScreen } = appConfig;
    const isLiveOnly = flowType === 'LIVE_ONLY';

    this.screensSequence = [
      { name: SCREENS.QUESTIONNAIRE, enabled: this.showQuestionnaireBefore() },
      { name: SCREENS.WELCOME, enabled: FLOW_V2_SHOW_WELCOME },
      { name: SCREENS.PRIVACY, enabled: isShownPrivacy(flowType) },
      { name: SCREENS.CAPTURE, enabled: !isLiveOnly },
      { name: SCREENS.VERIFY_DETAILS, enabled: !isLiveOnly && !disableVerifyDetailsScreen },
      {
        name: SCREENS.DATA_CHECK_ON_CONFIRM,
        enabled: () => {
          const { dataCheckOnConfirm } = this.state;
          return dataCheckOnConfirm && !isLiveOnly;
        }
      },
      {
        name: SCREENS.PROOF_OF_ADDRESS,
        enabled: () => {
          const { poaEnabled } = this.state;
          return poaEnabled && !isLiveOnly;
        }
      },
      { name: SCREENS.MORE_INFO, enabled: appConfig.showMoreInfoScreen },
      {
        name: SCREENS.FACE_SCAN,
        enabled: () => {
          const { hasLivenessScreen } = this.props;
          const { webrtc } = this.state;
          return hasLivenessScreen && !FLOW_V2_FORCE_ALTERNATE_FLOW && webrtc.status;
        }
      },
      {
        name: SCREENS.ALTERNATE_FLOW,
        enabled: () => {
          const { hasLivenessScreen } = this.props;
          const { selfieFR, webrtc } = this.state;
          return hasLivenessScreen && (FLOW_V2_FORCE_ALTERNATE_FLOW || selfieFR || !webrtc.status);
        }
      },
      {
        name: SCREENS.CAPTURE_EXTRA,
        enabled: appConfig.FLOW_V2_ADDITIONAL_DOC
      }
    ];

    this.state = {
      currentScreenName: null,
      idType: 'PASSPORT',
      tokenId: '',
      geolocation: '',
      error: null,
      tenMinsLeft: false,
      compatible: true,
      completed: false,
      isLandscape: false,
      isProcessing: false,
      isUploading: false,
      uploadBar: 0,
      webrtc: {
        todo: null,
        status: true
      },
      redirect: false,
      verify: false,
      verifyDetails: {},
      showLanguageSelectionPrompt: false,
      selfieFR: false,
      dataCheckOnConfirm: false,
      documentId: null,
      selectedDiffId: null,
      isFlowV2DiffId: false,
      showDetailMatch: false,
      captureVisa: false
    };

    this.input = null;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const { verify } = this.props;

    this.handleNextStep();

    this.setState({ verify });

    // Show detail match
    const path = window.location.pathname;
    const detailMatch = getCookie('detailMatch');
    if ((!detailMatch || detailMatch !== 'yes') && DETAIL_MATCH && DETAIL_MATCH.length > 0) {
      let showDetailMatch = true;
      if (DETAIL_MATCH_ONLY_UNIVERSAL_URL && path !== UNIVERSAL_URL_PATH) {
        showDetailMatch = false;
      }
      this.setState({
        showDetailMatch
      });
    }

    const { status: compatible, todo, os } = await isWebRTCSupported();

    this.setState({ compatible, webrtc: { todo, status: compatible } });

    if (os === 'iOS') {
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.scale !== 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    }

    // Detect if internet cut
    window.addEventListener('offline', () =>
      this.setState({
        error: {
          component: InternetCut,
          props: {
            onTryAgain: () => document.location.reload()
          }
        }
      })
    );
    // Detect if internet re connected
    window.addEventListener('online', () => {
      this.setState({
        error: {
          component: InternetCut,
          props: {
            onTryAgain: () => this.setState({ error: null })
          }
        }
      });
    });

    // Landscape
    const isLandscape = window.innerHeight < window.innerWidth;
    this.setState({ isLandscape });
    window.addEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });

    // Start the timeout for 1 hour.
    let countdown = 60 * 60;
    const timerId = setInterval(() => {
      if (countdown === 600) {
        this.setState({
          tenMinsLeft: true
        });
      }

      if (countdown <= 0) {
        this.setState({
          tenMinsLeft: false,
          error: {
            component: Timeout,
            props: {}
          }
        });
        clearInterval(timerId);
      }
      countdown--;
    }, 1000);
  }

  /**
   * Unbind the event listener
   */
  componentWillUnmount() {
    window.removeEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });
  }

  /**
   * Go to next step.
   *
   * @param {Object} state
   * @return {Void}
   */
  handleNextStep({ additionalInfo, ...state } = {}) {
    if (state.isDataOnlyCompleted) {
      this.finishFlow();
      return;
    }

    if ('captureVisa' in state && state.captureVisa) {
      this.setState({
        currentScreenName: SCREENS.CAPTURE,
        captureVisa: true,
        isFlowV2DiffId: true,
        selectedDiffId: {
          type: 'VISA'
        }
      });
      return;
    }

    this.setState({ ...state }, () => {
      const currentScreenIndex = this.getCurrentScreenIndex();
      const nextScreen = this.screensSequence
        .slice(currentScreenIndex + 1)
        .find(this.firstEnabledScreenPredicate);

      if (nextScreen) {
        this.setState({ currentScreenName: nextScreen.name });
      } else {
        this.finishFlow({ additionalInfo });
      }
    });
  }

  /**
   * Go back to pev step.
   *
   * @return {Void}
   */
  handleGoBack() {
    const currentScreenIndex = this.getCurrentScreenIndex();

    const previousScreen = this.screensSequence
      .slice(0, currentScreenIndex)
      .findLast(this.firstEnabledScreenPredicate);

    if (previousScreen) {
      this.setState({ currentScreenName: previousScreen.name });

      if (previousScreen.name === SCREENS.VERIFY_DETAILS) {
        this.setState({
          verifyDetails: {},
          selfieFR: false,
          dataCheckOnConfirm: false,
          poaEnabled: false,
          documentId: null,
          selectedDiffId: null,
          isFlowV2DiffId: false
        });
      }
    }
  }

  getCurrentScreenIndex = () => {
    const { currentScreenName } = this.state;
    return this.screensSequence.findIndex(
      (sequenceItem) => sequenceItem.name === currentScreenName
    );
  };

  // eslint-disable-next-line class-methods-use-this
  firstEnabledScreenPredicate = (sequenceItem) => {
    if (isFunction(sequenceItem.enabled)) {
      return sequenceItem.enabled();
    }
    return sequenceItem.enabled;
  };

  handleRedirection = ({ redirectTo = false, timeoutInSeconds = 3 }) => {
    if (redirectTo) {
      if (IMMEDIATE_REDIRECT) {
        APIs.return();
        return;
      }
      setTimeout(() => {
        APIs.return();
      }, timeoutInSeconds * 1000);
      this.setState({ isProcessing: false, isUploading: false, redirect: true, completed: true });
    } else {
      this.setState({ isProcessing: false, isUploading: false, completed: true });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  cleanCookies = () => {
    setCookie('retryAsf', 'no', -10);
    setCookie('retry', null, -7);
    setCookie('retryCaptureWithVideoStream', null, -7);
    setCookie('retryAttempt', null, -7);
    setCookie('idCaptureAttempt', 0, -7);
    setCookie('detailMatch', 'false', -1);
    setCookie('_permission', null, -7);
  };

  finishFlow = ({ additionalInfo = false } = {}) => {
    const { geolocation } = this.state;
    this.setState({ isProcessing: true });
    this.cleanCookies();
    APIs.markCompleted({ additionalInfo, geolocation }).then(({ redirectTo = null }) => {
      this.handleRedirection({ redirectTo });
    });
  };

  updateDocId = (documentId) => {
    this.setState({
      documentId
    });
  };

  handleChooseDiffId = (newDoc) => {
    this.setState({
      currentScreenName: SCREENS.CAPTURE,
      selectedDiffId: newDoc,
      isFlowV2DiffId: true
    });
  };

  clearDiffID = () => {
    this.setState({
      selectedDiffId: null
    });
  };

  questionnairePosition() {
    const { questionnaire } = this.props;
    return questionnaire?.position;
  }

  showQuestionnaireBefore() {
    const { questionnaire } = this.props;
    return questionnaire && this.questionnairePosition() === 'BEFORE';
  }

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    const {
      idType,
      tokenId,
      error,
      tenMinsLeft,
      compatible,
      completed,
      isProcessing,
      isUploading,
      uploadBar,
      isLandscape,
      geolocation,
      verify,
      webrtc = {},
      nextCapture,
      showLanguageSelectionPrompt,
      verifyDetails,
      useIdNumber,
      personNameFieldsOrder,
      isOldTurkishDL,
      documentId,
      selectedDiffId,
      isFlowV2DiffId,
      showDetailMatch,
      redirect,
      captureVisa,
      currentScreenName
    } = this.state;

    const { todo } = webrtc;
    const { component: Error, props: errorProps } = error || {};

    const { flowType, questionnaire } = this.props;
    const isLiveOnly = flowType === 'LIVE_ONLY';

    const openChromeBtns = [
      {
        label: localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_BUTTON_ANDROID'),
        full: true,
        onClick: () => {
          const { id } = document.body.dataset;
          document.location = `googlechrome://navigate?url=${document.location.href}${id}`;
          setTimeout(() => {
            this.setState({ compatible: true });
          }, 1000);
        }
      }
    ];
    const openSafariBtns = [
      {
        children: (
          <a className="link-inside-button" href="x-web-search://" target="_self">
            {localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_BUTTON_IOS')}
          </a>
        ),
        full: true,
        onClick: () => copyOpenSafari(this.input)
      }
    ];
    const tenMinsLeftBtns = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ tenMinsLeft: false })
      }
    ];

    /**
     * Exceptions for device compatible.
     */
    if (!compatible) {
      if (todo === 'OPEN_SAFARI') {
        return (
          <Message
            title={localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_IOS')}
            buttons={openSafariBtns}
            issue={localizedString('useSafari')}
          >
            {parse(localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_IOS'))}
            <input
              readOnly
              className="b-hidden-input"
              ref={(ref) => {
                this.input = ref;
              }}
            />
          </Message>
        );
      }
    }

    // Check if Browser is Firefox, as Firefox is not permitted for mobile flow
    if (isAndroidDevice() && !isBrowserPermitted()) {
      return <DeviceIncompatibleOpenChrome issue={false} buttons={openChromeBtns} />;
    }

    if (showDetailMatch) {
      return <DetailMatch />;
    }
    if (completed) {
      return <Success redirect={redirect} />;
    }

    if (showLanguageSelectionPrompt) {
      return <Language onGoback={() => this.setState({ showLanguageSelectionPrompt: false })} />;
    }

    return (
      <div>
        {Error && <Error {...errorProps} />}
        {isLandscape && <Message landscape />}

        {/* Pages */}
        <div inert={error ? 'true' : undefined}>
          {currentScreenName === SCREENS.QUESTIONNAIRE && (
            <Questionnaire questionnaire={questionnaire} onComplete={() => this.handleNextStep()} />
          )}
          {currentScreenName === SCREENS.WELCOME && (
            <Welcome onStart={() => this.handleNextStep()} />
          )}
          {currentScreenName === SCREENS.PRIVACY && (
            <PrivacyScreen
              flowType={flowType}
              onAccept={() => this.handleNextStep()}
              onSelectLanguage={() => this.setState({ showLanguageSelectionPrompt: true })}
            />
          )}

          {currentScreenName === SCREENS.CAPTURE && (
            <Capture
              captureVisa={captureVisa}
              isFlowV2DiffId={isFlowV2DiffId}
              selectedDiffId={selectedDiffId}
              onGoBack={this.handleGoBack}
              onNextStep={(stateChanges) => this.handleNextStep(stateChanges)} // TODO: This legacy code is an antipattern. Change on explicit param were made here in order to make it more obviuos that is bad thing.
              onCloseAcceptedDocs={this.clearDiffID}
              onGeoLocation={(geolocation) => this.setState({ geolocation })}
              nextCapture={nextCapture}
              setDocId={this.updateDocId}
              flowType={flowType}
              verify={verify}
            />
          )}

          {currentScreenName === SCREENS.VERIFY_DETAILS && (
            <VerifyDetails
              verifyDetails={verifyDetails}
              useIdNumber={useIdNumber}
              personNameFieldsOrder={personNameFieldsOrder}
              isOldTurkishDL={isOldTurkishDL}
              isFlowV2DiffId={isFlowV2DiffId}
              onChooseDiffId={(newDoc) => this.handleChooseDiffId(newDoc)}
              idType={idType}
              token={tokenId}
              verify={verify}
              flowType={flowType}
              location={geolocation}
              onNextStep={({ dataCheckOnConfirm, poaEnabled, captureVisa } = {}) =>
                this.handleNextStep({ dataCheckOnConfirm, poaEnabled, captureVisa })
              }
              retake={() => this.handleGoBack()}
              onGoBack={() => this.handleGoBack()}
            />
          )}

          {currentScreenName === SCREENS.DATA_CHECK_ON_CONFIRM && (
            <Datacheck
              isFlowV2DiffId={isFlowV2DiffId}
              documentId={documentId}
              onChooseDiffId={this.handleChooseDiffId}
              onNextStep={() => this.handleNextStep()}
            />
          )}

          {currentScreenName === SCREENS.PROOF_OF_ADDRESS && (
            <ProofOfAddress
              onBack={() => this.handleGoBack()}
              onNextStep={() => this.handleNextStep()}
            />
          )}

          {currentScreenName === SCREENS.MORE_INFO && (
            <MoreInfo
              token={tokenId}
              onNextStep={() => this.handleNextStep()}
              onGoBack={() => this.handleGoBack()}
            />
          )}

          {currentScreenName === SCREENS.FACE_SCAN && (
            <FaceScan
              idType={idType}
              tokenId={tokenId}
              onNextStep={({ selfieFR = false } = {}) => this.handleNextStep({ selfieFR })}
              onGoBack={isLiveOnly ? () => {} : () => this.handleGoBack()}
              location={geolocation}
            />
          )}
          {currentScreenName === SCREENS.ALTERNATE_FLOW && (
            <AlternateFlow
              onNextStep={() => this.handleNextStep()}
              onGoBack={isLiveOnly ? () => {} : () => this.handleGoBack()}
            />
          )}

          {currentScreenName === SCREENS.CAPTURE_EXTRA && (
            <CaptureExtra
              flowType={flowType}
              onNextStep={({ additionalInfo = false } = {}) =>
                this.handleNextStep({ additionalInfo })
              }
            />
          )}
        </div>

        {/* Exceptions */}
        {tenMinsLeft && (
          <Modal isOpen heading={localizedString('tenMinsLeftDesc')} buttons={tenMinsLeftBtns} />
        )}
        {/* End exceptions */}

        {/* Loadings */}
        {isUploading && <LoadingBar heading={localizedString('uploading')} width={uploadBar} />}
        {isProcessing && (
          <LoadingSpinner subtitle="" heading={localizedString('verifyingYourIdentity')} />
        )}
      </div>
    );
  }
}

App.propTypes = {
  verify: PropTypes.bool,
  appConfig: PropTypes.object,
  idDetails: PropTypes.object,
  questionnaire: PropTypes.object,
  flowType: PropTypes.string,
  hasLivenessScreen: PropTypes.bool
};

export default connect(mapStateToProps, null)(App);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    appConfig,
    idDetails: information.idDetails
  };
}
