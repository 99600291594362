import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Calendar from 'react-calendar';
import { convertDateToUTC, dateToString, monthYearToString, reverseDateFormatSF } from '@lib/Utils';
import { Button } from '@lib/components/v2/Form';
import classes from './DatePicker.style.module.scss';
import inputClasses from '../Input/Input.style.module.scss';

import 'react-calendar/dist/Calendar.css';

const getUtcDateValue = (value) => convertDateToUTC(reverseDateFormatSF(value, true));

const useCloseOnOutsideAction = ({ isOpen, setIsOpen, modalRef, inputRef }) => {
  const onOutsideAction = useCallback(
    (event) => {
      // Try event.composedPath first to handle clicks inside a Shadow DOM.
      const target = 'composedPath' in event ? event.composedPath()[0] : event.target;
      if (target && !inputRef.current?.contains(target) && !modalRef.current?.contains(target)) {
        setIsOpen(false);
      }
    },
    [modalRef, inputRef]
  );

  const handleOutsideActionListeners = useCallback(
    (shouldListen = isOpen) => {
      const outsideActionEvents = ['mousedown', 'focusin', 'touchstart'];
      outsideActionEvents.forEach((event) => {
        if (shouldListen) {
          document.addEventListener(event, onOutsideAction);
        } else {
          document.removeEventListener(event, onOutsideAction);
        }
      });
    },
    [isOpen, onOutsideAction]
  );

  useEffect(() => {
    handleOutsideActionListeners();

    return () => handleOutsideActionListeners(false);
  }, [handleOutsideActionListeners]);
};

/**
 * A wrapper around `react-mobile-datepicker` with `Input` component.
 */
export default function DatePicker(props) {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef();
  const inputRef = useRef();
  useCloseOnOutsideAction({ isOpen, setIsOpen, modalRef, inputRef });

  const openModal = () => {
    setIsOpen(true);
    setTimeout(() => {
      inputRef.current?.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }, 0);
  };

  const closeModal = () => setIsOpen(false);

  const handleSelect = (date) => {
    const { onChange, displayedDateFormat } = props;
    const showMonthDateOnly = displayedDateFormat === 'MM-YYYY';
    const value = showMonthDateOnly ? monthYearToString(date) : dateToString(date, false);
    closeModal();
    onChange(value);
  };

  const {
    value,
    className,
    onChange,
    hasError,
    disabled = false,
    displayedDateFormat,
    label,
    id,
    dataTestId,
    ...restProps
  } = props;
  const momentValue = moment(getUtcDateValue(value));
  const datePickerProps = {
    minDate: new Date(1900, 1, 1),
    value: value && momentValue.isValid() ? getUtcDateValue(value) : undefined
  };

  const inputDateValue =
    value && momentValue.isValid() ? momentValue.format(displayedDateFormat) : undefined;
  const showMonthDateOnly = displayedDateFormat === 'MM-YYYY';

  return (
    <div className={classes.wrapper}>
      <div className={className} ref={inputRef}>
        <div
          className={classNames(
            'inputWrapper',
            inputClasses.inputWrapper,
            className,
            inputClasses.leftPadding30Perc,
            hasError && inputClasses.hasError
          )}
        >
          <div
            id={id}
            data-testid={dataTestId}
            role="textbox"
            className={inputClasses.input}
            onFocus={() => !disabled && openModal()}
            tabIndex={0}
            aria-haspopup="dialog"
            aria-owns={`${restProps.id}-popup`}
          >
            {inputDateValue}
          </div>
          {hasError && <div className={inputClasses.error}>{hasError}</div>}
        </div>
        {!disabled && (
          <Button
            variant="transparent"
            className={classes.icon}
            onClick={() => !disabled && (isOpen ? closeModal() : openModal())}
            tabIndex={0}
          >
            <img alt="" src="images/icons/png/datepicker.png" />
          </Button>
        )}
      </div>
      <span
        ref={modalRef}
        id={`${restProps.id}-popup`}
        aria-modal="true"
        aria-hidden={isOpen ? 'false' : 'true'}
        className={classNames(classes.calendar, !isOpen ? classes.closed : '')}
      >
        <Calendar
          onChange={handleSelect}
          {...datePickerProps}
          maxDetail={showMonthDateOnly ? 'year' : 'month'}
        />
      </span>
    </div>
  );
}

DatePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.string,
  disabled: PropTypes.bool,
  displayedDateFormat: PropTypes.string,
  dataTestId: PropTypes.string
};

DatePicker.defaultProps = {
  className: '',
  value: '',
  name: '',
  id: '',
  label: '',
  onChange: () => null,
  displayedDateFormat: 'DD-MM-YYYY'
};
