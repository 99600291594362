import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { Title } from '@lib/components/v2/Title';
import { localizedString, isLocalizedStringDefined } from '@languages';
import {
  CAPTURE_ADDITIONAL_DOCS_MAX_LIMIT,
  FORCE_CAPTURE_ADDITIONAL_DOCS
} from '@spotMobileConfig';
import Animation, { VOI_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import APIs from '@services/APIs';

import { Error500 } from '@FLOW_V2_FLOW/errors';
import LoadingBar from '@lib/components/v2/LoadingBar';
import { Radio } from '@lib/components/v2/Form';
import classes from './CaptureExtra.style.module.scss';

class CaptureExtra extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    flowType: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.isComponentMounted = false;

    this.state = {
      optionList: [
        {
          type: 'YES',
          title: localizedString('yes'),
          checked: true
        },
        {
          type: 'NO',
          title: localizedString('no'),
          checked: false
        }
      ],
      progressBar: 0,
      isUploading: false,
      isCaptured: false,
      error: null,
      extraCaptureAttempt: 0
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  handleCapture(e) {
    if (!e.target.files[0]) {
      return;
    }
    const imagefile = e.target.files[0];
    e.target.value = '';

    this.uploadImage(imagefile);
    /* // test code
        this.setState({ isUploading: true, progressBar: 100 })
        setTimeout(() => {
            this.setState({ isUploading: false, progressBar: 0, isCaptured: true })
        }, 3000) */
  }

  handleNextStep(e) {
    e.currentTarget.disabled = true;
    if (e) {
      e.preventDefault();
    }

    const { onNextStep } = this.props;
    const { optionList } = this.state;

    const selectedItem = optionList.find((item) => item.checked);

    if (selectedItem.type === 'NO' && !FORCE_CAPTURE_ADDITIONAL_DOCS) {
      onNextStep();
    } else {
      this.element.click();
    }
  }

  onOptionClick(item, index) {
    if (item.checked) {
      return;
    }

    const { optionList } = this.state;
    for (let i = 0; i < optionList.length; i++) {
      optionList[i].checked = index === i;
    }
    this.setState({ optionList });
  }

  uploadImage(imagefile) {
    APIs.status('additionalInfo');
    const { flowType } = this.props;

    const params = { frontFile: imagefile };
    params.flowType = flowType;
    params.ocr = false;
    params.idType = 'Additional Documents';

    this.setState({ isUploading: true, progressBar: 0 });
    APIs.uploadImage(
      params,
      {
        before: () => this.setState({ progressBar: 0 }),
        onProgress: (width) => this.setState({ progressBar: width })
      },
      '/api/v4'
    )
      .then(({ status, msg: error }) => {
        const { isUploading, extraCaptureAttempt } = this.state;
        if (!isUploading) {
          return;
        }

        if (status === 'error') {
          if (this.isComponentMounted) {
            this.setState({
              error,
              isUploading: false
            });
          }
          return;
        }

        this.setState({
          isUploading: false,
          isCaptured: true,
          extraCaptureAttempt: extraCaptureAttempt + 1
        });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              this.setState({ error: null, isUploading: false, progressBar: 0 });
            }
          }
        };

        this.setState({ error });
      });
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { onNextStep } = this.props;
    const { optionList, progressBar, isUploading, isCaptured, error, extraCaptureAttempt } =
      this.state;
    const { component: Error, props: errorProps } = error || {};

    let footerButtons = [];
    if (isUploading) {
      footerButtons = [
        {
          label: localizedString(
            isLocalizedStringDefined('app.captureExtra.button.back')
              ? 'app.captureExtra.button.back'
              : 'back'
          ),
          variant: 'transparent',
          onClick: () => {
            this.setState({ isUploading: false });
          }
        },
        {
          label: 'Uploading',
          variant: 'transparent',
          loading: true
        }
      ];
    } else if (isCaptured) {
      footerButtons = [
        {
          label: localizedString('app.captureExtra.button.finish'),
          type: 'submit',
          onClick: (e) => {
            e.currentTarget.disabled = true;
            onNextStep({ additionalInfo: true });
          },
          dataTestId: 'docCapt-finish'
        }
      ];
    } else {
      footerButtons = [
        {
          label: localizedString(
            `app.captureExtra.button.${optionList[0].checked ? 'captureDocuments' : 'finish'}`
          ),
          type: 'submit',
          onClick: this.handleNextStep,
          dataTestId: 'docs-capture-button'
        }
      ];
    }

    return (
      <div>
        <input
          type="file"
          name="image"
          accept="image/*"
          capture="environment"
          onChange={this.handleCapture}
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
          aria-hidden="true"
        />
        {Error && <Error {...errorProps} />}
        {!Error && (
          <Page buttons={footerButtons} forceFillViewPort={isUploading || isCaptured}>
            {!isUploading && !isCaptured && (
              <div className={classes.wrapper}>
                <Title title={localizedString('app.captureExtra.heading')} />
                <div className={classes.description}>
                  {parse(localizedString('app.captureExtra.description'))}
                </div>
                <div className={classes.option_list}>
                  {optionList.map((item, index) => (
                    <Radio
                      key={item.title}
                      id={`docs-${index}`}
                      onChange={() => this.onOptionClick(item, index)}
                      checked={item.checked}
                      title={item.title}
                      data-testid={`docs-${item.title.toLowerCase()}`}
                      hidden={FORCE_CAPTURE_ADDITIONAL_DOCS}
                    />
                  ))}
                </div>
                {VOI_V2_ANIMATION_FILE_URLS.CAPTURE_EXTRA && (
                  <div className={classes['animation-container']}>
                    <Animation animationUrl={VOI_V2_ANIMATION_FILE_URLS.CAPTURE_EXTRA} />
                  </div>
                )}
              </div>
            )}

            {isUploading && (
              <div className={classNames(classes.wrapper, classes.fill_view_port)}>
                <Title title={localizedString('app.captureExtra.uploading.heading')} />
                <div className={classes.description}>
                  {localizedString('app.captureExtra.uploading.description')}
                </div>
                <div className={classes['animation-container']}>
                  <Animation animationUrl={VOI_V2_ANIMATION_FILE_URLS.REVIEW} />
                </div>
                <LoadingBar width={progressBar} />
              </div>
            )}

            {isCaptured && !isUploading && (
              <div className={classNames(classes.wrapper, classes.fill_view_port)}>
                <div className={classes.captured_content} data-testid="docCapt-txt">
                  <img alt="" src="images/icons/svg/icon_document.svg" data-testid="docCapt-img" />
                  {localizedString('app.captureExtra.captured.heading')}
                </div>
                {extraCaptureAttempt < CAPTURE_ADDITIONAL_DOCS_MAX_LIMIT && (
                  <button
                    type="button"
                    className={classes.capture_another}
                    onClick={() => this.element.click()}
                    data-testid="docCapt-anotherDoc"
                  >
                    {localizedString('app.captureExtra.button.captureAnother')}
                  </button>
                )}
              </div>
            )}
          </Page>
        )}
      </div>
    );
  }
}

export default CaptureExtra;
