import React from 'react';
import classnames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import Animation, { FLOW_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import classes from './Datacheck.style.module.scss';

const DatacheckLoading = () => {
  return (
    <div style={{ height: '100%' }}>
      <div className={classes.wrapper}>
        <Title data-testid="id-heading" title="Processing your verification" />
        <div className={classnames(classes.description)} data-testid="id-instruction">
          Matching your data to government database
        </div>
        <div className={classes['animation-container']} data-testid="id-animation">
          <Animation animationUrl={FLOW_V2_ANIMATION_FILE_URLS.REVIEW} />
        </div>
      </div>
    </div>
  );
};
export default DatacheckLoading;
