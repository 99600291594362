import React, { Component } from 'react';
import PropTypes from 'prop-types';
import APIs from '@services/APIs';
import { setCookie, getCookie } from '@lib/Utils';
import { localizedString } from '@languages';
import { LoadingSpinner } from '@FLOW_V2_FLOW/components';
import Prepare from '../../components/Contents/FaceScan/Prepare';
import { Error500 } from '../../errors';

export default class AlternateFlow extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func
  };

  static defaultProps = {
    onNextStep: () => null
  };

  constructor(props) {
    super(props);

    this.state = {
      isUploading: false,
      error: null
    };

    this.element = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  componentDidMount() {}

  /**
   * Handle change
   * @return {Void}
   */
  handleChange(e) {
    if (!e.target.files[0]) return;

    const { onGoBack, onNextStep } = this.props;

    const imagefile = e.target.files[0];
    e.target.value = '';

    const params = { face: imagefile, isEngineV4: true };

    this.setState({ isUploading: true });
    APIs.uploadSelfieImage(params, {}, '/api/v4')
      .then(({ status, msg: error, redirectTo }) => {
        if (status !== 'success') {
          this.setState({
            error,
            isUploading: false
          });
          return;
        }

        onNextStep({ completed: true, redirectTo });
      })
      .catch(() => {
        let attempt = parseInt(getCookie('retryAttempt'), 10) || 0;
        attempt += 1;
        setCookie('retryAttempt', attempt.toString(), 1);
        //---
        const error = {
          component: Error500,
          props: {
            onTryAgain: onGoBack
          }
        };
        this.setState({ error, isUploading: false });
      });
  }

  /**
   * Go back to the previous step
   * @return {Void}
   */
  handleGoBack() {
    const { onGoBack } = this.props;
    onGoBack();
  }

  render() {
    const { isUploading, error } = this.state;
    const { component: Error, props: errorProps } = error || {};
    return (
      <div>
        {Error && <Error {...errorProps} />}
        {isUploading && <LoadingSpinner heading={localizedString('uploading')} />}
        {!isUploading && (
          <div>
            <Prepare selfie onGoBack={this.handleGoBack} onStart={() => this.element.click()} />
          </div>
        )}
        <input
          type="file"
          name="image"
          accept="image/*"
          capture="user"
          onChange={this.handleChange}
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
          aria-hidden="true"
        />
      </div>
    );
  }
}
