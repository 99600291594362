import React, { memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import { Checkbox, DatePicker, Input } from '@lib/components/v2/Form';
import { getFields } from '@lib/utils/v2/getFields';
import { isDobUneditable } from '@lib/utils/v2/isDobUneditable';
import { AddressFinder } from '@lib/components/v2/AddressFinder';
import { calculateEditedInfo } from '@lib/utils/editedInfo';
import { isLocalizedStringDefined, localizedString } from '@languages';
import EditedAction from '@store/actions/edited';
import {
  FLOW_V2_DATEPICKER_FORMAT,
  FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2,
  FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_ANY_FIRST_DATA_REVIEW,
  FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_SECOND_DOCUMENTS,
  FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME,
  FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT,
  FLOW_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME
} from '@spotMobileConfig';

import parse from 'html-react-parser';
import classes from './VerifyDetails.style.module.scss';
import { getValidations } from './verifyDetailsValidations';

const VerifyDetails = memo(
  ({
    idType,
    cardType,
    countryCode: country,
    countryOfIssue,
    onChange,
    address,
    addressNative,
    checkConfirm,
    addressData,
    engine4Config,
    useIdNumber,
    personNameFieldsOrder,
    isOldTurkishDL,
    isSubmiting,
    ...restProps
  }) => {
    const formStateRef = useRef();
    const dispatch = useDispatch();
    const extracted = useSelector(({ information }) => information?.extracted);
    const hasAddressBeenAlreadyChanged = useSelector(({ edited }) =>
      edited.fields.some((editedField) => editedField === 'addressData')
    );

    const fieldEditLimitCount = useSelector(({ appConfig }) => appConfig?.field_edit_limit_count);
    const disabledFields = [];

    if (
      isDobUneditable({
        countryCodeIso2: country,
        idType,
        forceRestriction: FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_ANY_FIRST_DATA_REVIEW,
        restrictedSecondDocsList: FLOW_V2_UNABLE_TO_EDIT_DOB_FOR_SECOND_DOCUMENTS
      })
    ) {
      disabledFields.push('dateOfBirth');
    }

    const fields = getFields({
      idType,
      countryOfIssue,
      addressData,
      useIdNumber,
      personNameFieldsOrder,
      disabledFields,
      isOldTurkishDL,
      values: restProps,
      flowType: 'FLOW_V2'
    });

    const formValidations = getValidations({
      idType,
      countryOfIssue,
      values: restProps,
      flowType: 'FLOW_V2',
      cardType
    });

    const confirmNameIsEmptyFields = {
      firstName: {
        id: 'confirmFirstNameIsEmpty',
        label: localizedString('noFirstname')
      },
      middleName: {
        id: 'confirmMiddleNameIsEmpty',
        label: localizedString('noMiddlename')
      },
      lastName: {
        id: 'confirmLastNameIsEmpty',
        label: localizedString('noSurname')
      }
    };

    const showEmptyNameCheckbox = (id, fields) => {
      if (!FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME) {
        return false;
      }

      if (!['firstName', 'middleName', 'lastName'].includes(id)) {
        return false;
      }

      if (fields[id] !== '') {
        return false;
      }

      return true;
    };

    const handleChange = ({ id, value, limitEdit = false } = {}) => {
      const formState = formStateRef.current || {};

      const { changedFields, editedCount } = calculateEditedInfo({
        extracted,
        fields: { ...formState.fields, [id]: value },
        dirty: formState.dirty,
        hasAddressBeenAlreadyChanged
      });

      if (limitEdit && editedCount > fieldEditLimitCount) {
        formState?.setField?.({ tooManyFieldsEdited: true });
        return;
      }

      dispatch(EditedAction.setEditedFields(changedFields));
      onChange(id, value);
      formState?.setField?.({ [id]: value, tooManyFieldsEdited: false });
    };

    /**
     * Generate form fields.
     */
    const generateFields = fields.map((field, i) => {
      const { id, label, value, date, addressFinder = false, disabled = false } = field;
      const dataTestIdPrefix = `details-field-${i}-`;
      const labelDataTestId = `${dataTestIdPrefix}lbl`;
      let countries = null;
      if (engine4Config) {
        countries = engine4Config.acceptedCountries;
      }
      if (date) {
        return (
          <div key={id} className={classNames('', classes.userDetailRow)}>
            <label data-testid={labelDataTestId} id={`label-${id}`} htmlFor={id}>
              {field.label}
            </label>
            <Validation config={formValidations[id]} initialValues={{ [id]: value }}>
              {({ errors }) => {
                return (
                  <DatePicker
                    key={id}
                    className={classNames(classes.input)}
                    hasError={errors[id]}
                    onChange={(value) => {
                      handleChange({ id, value, limitEdit: true });
                    }}
                    id={id}
                    label={label}
                    value={restProps[id]}
                    disabled={disabled || isSubmiting}
                    dataTestId={`${dataTestIdPrefix}txt`}
                    displayedDateFormat={FLOW_V2_DATEPICKER_FORMAT}
                  />
                );
              }}
            </Validation>
          </div>
        );
      }
      if (addressFinder) {
        let countryCodeIso2ToVerify;
        if (value && value.manual) {
          countryCodeIso2ToVerify = value.selectedManualCountryCode
            ? value.selectedManualCountryCode
            : 'AU';
        } else {
          countryCodeIso2ToVerify = FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 || country;
        }

        return (
          <div key={id} className={classNames('', classes.userDetailRow)}>
            <label
              data-testid={labelDataTestId}
              className={classes['address-label-span']}
              htmlFor="homeAddress"
            >
              {field.label || localizedString('residentalAddress')}
            </label>
            <AddressFinder
              initialAddress={address}
              initialNativeAddress={addressNative}
              initialAddressData={addressData}
              countryCodeIso2ToVerify={countryCodeIso2ToVerify}
              availableManualCountryCodesIso3={countries}
              onChange={(value) => {
                handleChange({ id, value, limitEdit: false });
              }}
              dataTestId={`${dataTestIdPrefix}txt`}
              disabled={isSubmiting}
            />
          </div>
        );
      }
      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <label data-testid={labelDataTestId} id={`label-${id}`} htmlFor={id}>
            {field.label}
          </label>
          <Validation config={formValidations[id]} initialValues={{ [id]: value }}>
            {({ dirty, errors, fields }) => {
              // manual validating middleName on initializing because init does not validate
              if (
                FLOW_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME &&
                id === 'middleName' &&
                value.length === 1
              ) {
                // eslint-disable-next-line no-param-reassign
                dirty.middleName = true;
              }

              return (
                <div>
                  <Input
                    key={id}
                    placeholder={field.label}
                    paddingLeft30
                    className={classNames(classes.input, classes.error)}
                    hasError={errors[id]}
                    onChange={(value) => {
                      handleChange({ id, value, limitEdit: true });
                    }}
                    {...field}
                    dataTestId={`${dataTestIdPrefix}txt`}
                    disabled={isSubmiting || disabled}
                  />
                  {showEmptyNameCheckbox(id, fields) && (
                    <Checkbox
                      className={classNames(classes['confirm-name-empty'], classes.error)}
                      name={confirmNameIsEmptyFields[id].id}
                      id={confirmNameIsEmptyFields[id].id}
                      value={fields[confirmNameIsEmptyFields[id]]}
                      label={confirmNameIsEmptyFields[id].label}
                    />
                  )}
                </div>
              );
            }}
          </Validation>
        </div>
      );
    });

    return (
      <div className={classes.wrapper}>
        <Title
          data-testid="details-heading"
          title={localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_HEADING')}
        />
        {isLocalizedStringDefined('verifyDetails.FLOW_V2_VERIFY_DETAILS_DESCRIPTION') && (
          <div className={classes.description}>
            {parse(localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_DESCRIPTION'))}
          </div>
        )}
        <div className={classes.container} aria-live="polite">
          {generateFields}
          <Validation
            config={{
              tooManyFieldsEdited: {
                isEqual: {
                  message: localizedString('verifyDetails.FLOW_V2_EDIT_TOO_MANY_FIELDS_TITLE'),
                  value: false
                }
              }
            }}
            initialValues={{ tooManyFieldsEdited: false }}
          >
            {(formState) => {
              formStateRef.current = formState;
              return <div />;
            }}
          </Validation>
          {FLOW_V2_VERIFY_DETAILS_SHOW_CONSENT && (
            <Checkbox
              className={classNames(classes['check-confirm'])}
              id="confirm"
              value={checkConfirm}
              onChange={(value) => onChange('checkConfirm', value)}
              label={localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_CONSENT')}
              dataTestId="details-checkbox"
              labelDataTestId="details-consentTxt"
              disabled={isSubmiting}
            />
          )}
        </div>
      </div>
    );
  }
);

VerifyDetails.propTypes = {
  idType: PropTypes.string,
  cardType: PropTypes.string,
  onChange: PropTypes.func,
  /* Form data */
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  expiryDate: PropTypes.string,
  licenceNumber: PropTypes.string,
  licenceVersion: PropTypes.string,
  passportNumber: PropTypes.string,
  /* Confirmation */
  checkConfirm: PropTypes.bool,
  checklicenceNumber: PropTypes.bool,
  checklicenceVersion: PropTypes.bool,
  checkpassportNumber: PropTypes.bool,
  engine4Config: PropTypes.object,

  countryCode: PropTypes.string,
  countryOfIssue: PropTypes.string,
  addressData: PropTypes.object,
  useIdNumber: PropTypes.bool,
  personNameFieldsOrder: PropTypes.arrayOf(PropTypes.string),
  address: PropTypes.any,
  addressNative: PropTypes.string,
  isOldTurkishDL: PropTypes.bool,
  isSubmiting: PropTypes.bool
};

VerifyDetails.defaultProps = {
  idType: 'NZL_DRIVERLICENCE',
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  expiryDate: '',
  passportNumber: '',
  licenceNumber: '',
  licenceVersion: '',
  onChange: () => {},
  engine4Config: {},
  isOldTurkishDL: false,
  isSubmiting: false
};

export default VerifyDetails;
