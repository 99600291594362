import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import classNames from 'classnames';
import { Button } from '@lib/components/v2/Form';
import classes from './View.style.module.scss';
import ViewAudio from './View.audio';

export default class View extends Component {
  static propTypes = {
    /* Animation timeout */
    delay: PropTypes.number,
    timeout: PropTypes.number,
    /* Animation data */
    animation: PropTypes.object,
    autoplay: PropTypes.bool,
    onComplete: PropTypes.func,
    onMuteAudio: PropTypes.func,
    muted: PropTypes.bool,
    dire: PropTypes.bool,
    hasError: PropTypes.bool,
    repeat: PropTypes.bool,
    load: PropTypes.bool,
    rightSide: PropTypes.bool,
    children: PropTypes.node,
    audio: PropTypes.any,
    audioType: PropTypes.string
  };

  static defaultProps = {
    timeout: 1,
    delay: 0,
    animation: null,
    autoplay: true,
    onComplete: () => null,
    onMuteAudio: () => null
  };

  constructor(props) {
    super(props);

    this.container = null;
    this.audioTimeout = null;
  }

  /**
   * Start an animation
   * @return {Void}
   */
  componentDidMount() {
    const {
      delay,
      animation: animationData,
      timeout,
      autoplay,
      onComplete,
      audio,
      audioType,
      dire,
      rightSide,
      repeat,
      load,
      muted
    } = this.props;

    if (load) {
      return;
    }

    if (audio) {
      this.audioTimeout = setTimeout(
        () => {
          ViewAudio.play(audio, audioType, muted, repeat);
        },
        audioType === 'position' && !repeat ? 3000 : 0
      );
    }

    if (this.container && animationData) {
      const rendererSettings = {
        viewBoxOnly: true,
        viewBoxSize: '80 10 150 150'
      };
      if (audioType === 'position') {
        rendererSettings.viewBoxSize = '-13 40 696 620';
      }
      if (audioType === 'thanks') {
        rendererSettings.viewBoxSize = '411 283 310 310';
      }
      if (audioType === 'smile') {
        rendererSettings.viewBoxSize = '143 82 620 620';
      }
      if (dire) {
        if (rightSide) {
          rendererSettings.viewBoxSize = '407 283 310 310';
        } else {
          rendererSettings.viewBoxSize = '265 71 618 618';
        }
      }
      const animation = lottie.loadAnimation({
        container: this.container,
        animationData,
        autoplay: false,
        loop: repeat,
        rendererSettings
      });
      setTimeout(() => autoplay && animation.play(), delay * 1000);
    }

    setTimeout(() => {
      onComplete();
    }, (timeout + delay) * 1000);
  }

  componentWillUnmount() {
    const { audio } = this.props;
    if (this.audioTimeout) {
      clearTimeout(this.audioTimeout);
    }
    if (audio) {
      audio.pause();
    }
  }

  render() {
    const { children, hasError, muted, audioType, onMuteAudio } = this.props;

    return (
      <div className={classes.wrapper}>
        <div
          className={classNames(
            classes.frame,
            audioType !== 'position' && classes.success,
            hasError && classes.error
          )}
        >
          <div className={classes.header}>
            <div
              className={classNames(classes.animation, classes.dire)}
              ref={(ref) => {
                this.container = ref;
              }}
            />
            <h4>{children}</h4>
          </div>
        </div>
        <Button
          type="button"
          variant="link"
          onClick={onMuteAudio}
          className={classNames(classes.audio, muted && classes.muted)}
        >
          <i />
        </Button>
      </div>
    );
  }
}
