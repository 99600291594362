import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Title } from '@lib/components/v2/Title';
import APIs from '@services/APIs';
import Header from '@lib/components/v2/Page/Header';
import { isLocalizedStringDefined, localizedString } from '@languages';
import { useLogo } from '@js/hooks/useLogo';
import { SUCCESS_ANIMATION_SIZE_SCALE, SUCCESS_SCREEN_HIDE_HEADER } from '@spotMobileConfig';
import './Success.style.scss';
import Animation, { FLOW_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import { Button } from '@lib/components/v2/Form';

const Success = ({ successMessage, redirect = false }) => {
  document.body.style.background = '#fff';

  const finalLogo = useLogo({ isSuccessScreen: true });

  const handleRedirect = () => {
    if (redirect) {
      APIs.return();
    }
  };

  let successMessageTitle = localizedString('verificationComplete.title');

  if (redirect && isLocalizedStringDefined('verificationComplete.titleRedirect')) {
    successMessageTitle = localizedString('verificationComplete.titleRedirect');
  }

  if (successMessage) {
    successMessageTitle = successMessage;
  }

  return (
    <div className="success-flowv2-screen" aria-live="assertive">
      <div>
        {!SUCCESS_SCREEN_HIDE_HEADER && (
          <div className="feedback-link-container-success">
            <Header logo={finalLogo} />
          </div>
        )}

        <div className="wrapper">
          {!SUCCESS_SCREEN_HIDE_HEADER && (
            <>
              <Title
                className={successMessage ? 'heading-small' : 'heading'}
                data-testid="cmplt-heading"
                title={successMessageTitle}
              />
              <Button
                type="button"
                variant="link"
                className="title"
                onClick={handleRedirect}
                data-testid="cmplt-instruction"
              >
                {redirect
                  ? parse(localizedString('verificationComplete.redirectedDescription'))
                  : parse(localizedString('verificationComplete.youCanCloseThisWindow'))}
              </Button>
            </>
          )}

          {FLOW_V2_ANIMATION_FILE_URLS.SUCCESS && (
            <div className="animation-container" data-testid="cmplt-animation">
              <Animation
                scale={SUCCESS_ANIMATION_SIZE_SCALE}
                animationUrl={FLOW_V2_ANIMATION_FILE_URLS.SUCCESS}
              />
            </div>
          )}

          {SUCCESS_SCREEN_HIDE_HEADER && <div className="footer">{successMessageTitle}</div>}
        </div>
      </div>
    </div>
  );
};
Success.propTypes = {
  successMessage: PropTypes.any,
  redirect: PropTypes.bool
};
export default Success;
