import React from 'react';
import PropTypes from 'prop-types';
import Message from '@lib/components/v2/Message';

export const Error500 = (props) => {
  return (
    <Message {...props} title="An error has occurred" issue>
      Please refresh this page to reattempt.
    </Message>
  );
};

/**
 * Error 400
 */
export const Error400 = (props) => {
  const { onRetryAgain = () => {}, ...restProps } = props;
  return (
    <Message
      {...restProps}
      buttons={[
        {
          label: 'Try Again',
          full: true,
          onClick: onRetryAgain
        }
      ]}
      title="Error - Unstable internet connection"
      issue
    >
      Press "Try Again" to reattempt
    </Message>
  );
};

Error400.propTypes = {
  onRetryAgain: PropTypes.func
};

/**
 * Connectivity issue
 */
export const InternetCut = (props) => {
  return (
    <Message
      {...props}
      buttons={[
        {
          label: 'Back',
          variant: 'transparent',
          onClick: () => document.location.reload()
        },
        {
          label: 'Try Again',
          shadow: true,
          onClick: () => document.location.reload()
        }
      ]}
      title="Connection error"
      issue
    >
      Please check your internet connection and click try again below to reconnect.
    </Message>
  );
};

/**
 * Device Exceptions
 * */

export const DeviceIncompatibleOpenChrome = ({ issue = true, ...restProps }) => {
  return (
    <Message
      {...restProps}
      issue={issue}
      title="You will need to open a different browser to proceed."
    >
      Tap "Proceed" to continue.
    </Message>
  );
};

DeviceIncompatibleOpenChrome.propTypes = {
  issue: PropTypes.bool
};
