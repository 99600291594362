import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Title } from '@lib/components/v2/Title';
import Page from '@lib/components/v2/Page';
import classes from './Intro.style.module.scss';
import { RequiredDocuments } from '..';

class Intro extends Component {
  static propTypes = {
    onBegin: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      showRequiredDocuments: false,
      requiredDocumentCount: 2
    };
  }

  componentDidMount() {}

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { onBegin } = this.props;
    const { showRequiredDocuments, requiredDocumentCount } = this.state;

    return (
      <div>
        {!showRequiredDocuments && (
          <Page isDark forceFillViewPort>
            <div className={classes.wrapper}>
              <Title
                className={classes.heading}
                title="Welcome to the OCR Labs <span>Digital VOI service.</span>"
              />
              <div className={classes.description}>
                Please make sure you have the correct ID documents before you begin. This will
                ensure you get through your VOI as quickly as possible.
              </div>
              <div className={classes.space} />
              <button
                type="button"
                className={classes.link}
                onClick={() => this.setState({ showRequiredDocuments: true })}
              >
                See your required documents
                <span>
                  <img alt="" src="images/icons/svg/next_in_dark_mode.svg" />
                </span>
              </button>
              <button
                type="button"
                className={classes.begin}
                onClick={() => onBegin(requiredDocumentCount)}
              >
                Begin
              </button>
            </div>
          </Page>
        )}

        {showRequiredDocuments && (
          <RequiredDocuments
            onGoBack={(requiredDocumentCount) =>
              this.setState({
                showRequiredDocuments: false,
                requiredDocumentCount
              })
            }
          />
        )}
      </div>
    );
  }
}

export default Intro;
