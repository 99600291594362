import React from 'react';
import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import Animation, { FLOW_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import parse from 'html-react-parser';
import { Title } from '@lib/components/v2/Title';
import classes from './BackOfCard.style.module.scss';

/**
 * Back of card animation.
 */
export default function BackOfCard({ idType }) {
  const backDesc = idType.match(/DRIVERLICENCE/i)
    ? localizedString('capture.FLOW_V2_BACKCARD_HEADING_DL')
    : localizedString('capture.FLOW_V2_BACKCARD_HEADING_CARD');

  const continueDesc = idType.match(/DRIVERLICENCE/i)
    ? localizedString('capture.FLOW_V2_BACKCARD_DESCRIPTION_DL')
    : localizedString('capture.FLOW_V2_BACKCARD_DESCRIPTION_CARD');

  return (
    <div className={classes.wrapper}>
      <Title title={backDesc} />
      <div className={classes.description}>{parse(continueDesc)}</div>
      <div className={classes['animation-container']}>
        <Animation scale={1.2} animationUrl={FLOW_V2_ANIMATION_FILE_URLS.BACK_CARD} />
      </div>
    </div>
  );
}

BackOfCard.propTypes = {
  idType: PropTypes.string
};

BackOfCard.defaultProps = {
  idType: 'PASSPORT'
};
