export const SCREENS = {
  ALTERNATE_FLOW: 'ALTERNATE_FLOW',
  CAPTURE: 'CAPTURE',
  CAPTURE_EXTRA: 'CAPTURE_EXTRA',
  DATA_CHECK_ON_CONFIRM: 'DATA_CHECK_ON_CONFIRM',
  FACE_SCAN: 'FACE_SCAN',
  MORE_INFO: 'MORE_INFO',
  PRIVACY: 'PRIVACY',
  PROOF_OF_ADDRESS: 'PROOF_OF_ADDRESS',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  VERIFY_DETAILS: 'VERIFY_DETAILS',
  WELCOME: 'WELCOME'
};
