import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Title } from '@lib/components/v2/Title';
import Page from '@lib/components/v2/Page';
import { Radio } from '@lib/components/v2/Form';
import classes from './MedicareNames.style.module.scss';

class MedicareNames extends Component {
  static propTypes = {
    names: PropTypes.array,
    onSelected: PropTypes.func,
    onGoBack: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      optionList: []
    };
    this.handleNextStep = this.handleNextStep.bind(this);
  }

  async componentDidMount() {
    const { names = {} } = this.props;
    const optionList = await Object.keys(names).map((key) => {
      return {
        title: `${key} ${names[key]}`,
        checked: false
      };
    });
    this.setState({
      optionList
    });
  }

  handleNextStep(e) {
    if (e) {
      e.preventDefault();
    }
    const { onSelected } = this.props;
    const { optionList } = this.state;
    const selectedItem = optionList.find((item) => item.checked);
    if (selectedItem) {
      const parts = selectedItem.title.split(/\s(.+)/);
      const data = {
        fullName: parts[1],
        medicareRefNumber: parts[0]
      };
      onSelected(data);
    } else {
      console.error('Please select a name');
    }
  }

  onOptionClick(item, index) {
    if (item.checked) {
      return;
    }

    const { optionList } = this.state;
    for (let i = 0; i < optionList.length; i++) {
      optionList[i].checked = index === i;
    }
    this.setState({ optionList });
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { onGoBack } = this.props;
    const { optionList } = this.state;
    const footerButtons = [
      {
        label: 'Back',
        variant: 'transparent',
        onClick: onGoBack
      },
      {
        label: 'Continue',
        type: 'submit',
        onClick: this.handleNextStep
      }
    ];

    return (
      <Page buttons={footerButtons}>
        <div className={classes.wrapper}>
          <Title title="Which number reference are you?" />
          <div className={classes.option_list}>
            {optionList.map((item, index) => (
              <Radio
                key={item.title}
                id={`medicareName-${index}`}
                onChange={() => this.onOptionClick(item, index)}
                checked={item.checked}
                title={item.title}
                className={classes.option_item}
              />
            ))}
          </div>
        </div>
      </Page>
    );
  }
}

export default MedicareNames;
